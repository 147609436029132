import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import { Modal } from 'antd'
import { MapsPlacesAutocomplete } from '@/components/shared/MapsPlacesAutocomplete'
import googleMapsAutocompleteParser from '@/utils/googleMapsAutocompleteParser'
import { CustomerAddressProps } from '@/Props/CustomerAddressProps'
import { useSession } from 'next-auth/client'
import { CategoryProps } from '@/Props/CategoryProps'
//import { useCart } from './cart'

interface ClientProviderProps {
  children: ReactNode
}

interface ClientContextData {
  address: string
  addressId: string
  latitude: string
  longitude: string
  city: string
  isLoading: boolean
  changeIsLoading: (value: boolean) => void
  country: string
  district: string
  postalCode: string
  doingLogin: boolean
  changeDoingLogin: (value: boolean) => void
  changeClientAddress: (newAddress: string) => void
  changeClientAddressId: (newAddressId: string) => void
  changeClientCoordinates: ({ latitude, longitude }: ChangeClientCoordinatesProps) => void
  changeClientCity: (newCity: string) => void
  changeClientCountry: (newCountry: string) => void
  changeClientDistrict: (newAddress: string) => void
  changeClientPostalCode: (newAddress: string) => void
  showChangeAddressModal: () => void
  changeAddressModal: () => JSX.Element
  isChangeAddressModalVisible: boolean
  closePopup: () => void
  isClientLoading: boolean
  addressName: string
  changeAddressName: (name: string) => void
  updatedInfo: boolean
  changeUpdatedInfo: (value: boolean) => void
  showSpinnerSearchInput: boolean
  changeShowSpinnerSearchInput: (showSpinner: boolean) => void
  addressError: boolean
  changeAddressError: (value: boolean) => void
  addressData: CustomerAddressProps[]
  changeAddressData: (value: CustomerAddressProps[]) => void
  categorySelected: CategoryProps
  changeCategorySelected: (value: CategoryProps) => void
  deliveryZoneError: boolean
  changeDeliveryZoneError: (value: boolean) => void
  ip: string
  changeIp: (ip: string) => void
  isVisibleAfterHours: boolean
  changeIsVisibleAfterHours: (value: boolean) => void
  isVisibleAfterHoursLoading: boolean
  changeIsVisibleAfterHoursLoading: (value: boolean) => void
  clientFromIslands: string
  changeClientFromIslands: (value: string) => void
}

interface ChangeClientCoordinatesProps {
  longitude: string
  latitude: string
}

export const ClientContext = createContext({} as ClientContextData)

export const ClientProvider = ({ children }: ClientProviderProps) => {
  const [address, setAddress] = useState('')
  const [addressId, setAddressId] = useState(Cookies.get('clientAddressId'))
  const [latitude, setLatitude] = useState(Cookies.get('clientLatitude'))
  const [longitude, setLongitude] = useState(Cookies.get('clientLongitude'))
  const [city, setCity] = useState(Cookies.get('clientCity'))
  const [country, setCountry] = useState(Cookies.get('clientCountry'))
  const [district, setDistrict] = useState(Cookies.get('clientDistrict'))
  const [postalCode, setPostalCode] = useState(Cookies.get('clientPostalCode'))
  const [isChangeAddressModalVisible, setIsChangeAddressModalVisible] = useState(false)
  const [modalSearchAddress, setModalSearchAddress] = useState('')
  const [isClientLoading, setIsClientLoading] = useState(true)
  const [addressName, setAddressName] = useState('')
  const [updatedInfo, setUpdatedInfo] = useState(false)
  const [doingLogin, setDoingLogin] = useState(false)
  const [showSpinnerSearchInput, setShowSpinnerSearchInput] = useState(false)
  const [addressError, setAddressError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [addressData, setAddressData] = useState<CustomerAddressProps[]>([])
  const [session] = useSession()
  const [categorySelected, setCategorySelected] = useState<CategoryProps>()
  const [deliveryZoneError, setDeliveryZoneError] = useState(false)
  const [isVisibleAfterHours, setIsVisibleAfterHours] = useState(false)
  const [isVisibleAfterHoursLoading, setIsVisibleAfterHoursLoading] = useState(false)
  const [clientFromIslands, setClientFromIslands] = useState<string>(
    Cookies.get('clientDistrict') == 'Madeira'
      ? 'madeira'
      : Cookies.get('clientDistrict') == 'Açores' || Cookies.get('clientDistrict') == 'Azores'
      ? 'açores'
      : ''
  )
  const [ip, setIp] = useState('')
  useEffect(() => {
    setAddress(Cookies.get('clientAddress') ? Cookies.get('clientAddress') : '')
  }, [])

  useEffect(() => {
    if (!isLoading && address && !latitude && !longitude) {
      setAddressError(true)
    } else {
      setAddressError(false)
    }
  }, [address, latitude, longitude])

  const changeIp = (ip: string) => {
    setIp(ip)
  }
  const changeClientFromIslands = (value: string) => {
    setClientFromIslands(value)
  }
  const changeIsVisibleAfterHoursLoading = (value: boolean) => {
    setIsVisibleAfterHoursLoading(value)
  }
  const changeIsVisibleAfterHours = (value: boolean) => {
    setIsVisibleAfterHours(value)
  }
  const changeIsLoading = (value: boolean) => {
    setIsLoading(value)
  }

  const changeAddressError = (value: boolean) => {
    setAddressError(value)
  }

  const changeDeliveryZoneError = (value: boolean) => {
    setDeliveryZoneError(value)
  }

  const changeCategorySelected = (value: CategoryProps) => {
    setCategorySelected(value)
  }

  const changeAddressData = (value: CustomerAddressProps[]) => {
    setAddressData(value)
  }

  const changeDoingLogin = (value: boolean) => {
    setDoingLogin(value)
  }

  const changeUpdatedInfo = (value: boolean) => {
    setUpdatedInfo(value)
  }

  const changeShowSpinnerSearchInput = (showSpinner: boolean) => {
    setShowSpinnerSearchInput(showSpinner)
  }

  const changeClientAddress = (newAddress: string) => {
    if (newAddress == '') {
      setAddress('')
      setAddressId('')
      setLatitude('')
      setLongitude('')
      setCity('')
      setCountry('')
      setDistrict('')
      setPostalCode('')
      setAddressName('')
      Cookies.remove('clientAddress')
      Cookies.remove('clientLatitude')
      Cookies.remove('clientLongitude')
      Cookies.remove('clientCity')
      Cookies.remove('clientCountry')
      Cookies.remove('clientDistrict')
      Cookies.remove('clientPostalCode')

      setLatitude(null)
      setLongitude(null)
    }
    setAddress(newAddress)
    Cookies.set('clientAddress', newAddress, { expires: 60 })
  }

  const changeAddressName = (name: string) => {
    setAddressName(name)
  }

  const changeClientAddressId = (newAddressId: string) => {
    if (newAddressId != '') {
      setAddressId(newAddressId)
      Cookies.set('clientAddressId', newAddressId, { expires: 60 })
    } else {
      setAddressId(newAddressId)
      Cookies.remove('clientAddressId')
    }
  }

  const changeClientCoordinates = ({ latitude, longitude }: ChangeClientCoordinatesProps) => {
    setLatitude(latitude)
    setLongitude(longitude)
    Cookies.set('clientLatitude', latitude, { expires: 60 })
    Cookies.set('clientLongitude', longitude, { expires: 60 })

    if (latitude != '' && longitude != '') {
      setLatitude(latitude)
      setLongitude(longitude)
      Cookies.set('clientLatitude', latitude, { expires: 60 })
      Cookies.set('clientLongitude', longitude, { expires: 60 })
    } else {
      setLatitude(latitude)
      setLongitude(longitude)
      Cookies.remove('clientLatitude')
      Cookies.remove('clientLongitude')
    }
  }

  const changeClientCity = (newCity: string) => {
    setCity(newCity)
    Cookies.set('clientCity', newCity, { expires: 60 })

    if (newCity != '') {
      setCity(newCity)
      Cookies.set('clientCity', newCity, { expires: 60 })
    } else {
      setCity(newCity)
      Cookies.remove('clientCity')
    }
  }

  const changeClientDistrict = (newDistrict: string) => {
    if (newDistrict != '') {
      setDistrict(newDistrict)
      Cookies.set('clientDistrict', newDistrict, { expires: 60 })
    } else {
      setDistrict(newDistrict)
      Cookies.remove('clientDistrict')
    }
  }

  const changeClientPostalCode = (newPostalCode: string) => {
    setPostalCode(newPostalCode)
    Cookies.set('clientPostalCode', newPostalCode, { expires: 60 })
    if (newPostalCode != '') {
      setPostalCode(newPostalCode)
      Cookies.set('clientPostalCode', newPostalCode, { expires: 60 })
    } else {
      setPostalCode(newPostalCode)
      Cookies.remove('clientPostalCode')
    }
  }

  const changeClientCountry = (newCountry: string) => {
    setCountry(newCountry)
    Cookies.set('clientCountry', newCountry, { expires: 60 })
    if (newCountry != '') {
      setCountry(newCountry)
      Cookies.set('clientCountry', newCountry, { expires: 60 })
    } else {
      setCountry(newCountry)
      Cookies.remove('clientCountry')
    }
  }

  const showChangeAddressModal = () => {
    setIsChangeAddressModalVisible(true)
  }

  const closePopup = () => {
    setIsChangeAddressModalVisible(false)
  }

  const handleChangeAddress = (address: string) => {
    setModalSearchAddress(address)
  }

  const handleSelectAddress = async (address: string, placeId: string) => {
    const response = await googleMapsAutocompleteParser({ placeId: placeId })
    if (response.postal_code && response.postal_code.length > 0) {
      changeClientAddressId(placeId)
      if (response.city && response.street) {
        changeClientAddress(`${response.street}, ${response.city}`)
      } else if (response.street) {
        changeClientAddress(response.street)
      } else if (response.city) {
        changeClientAddress(response.city)
      } else {
        changeClientAddress(response.formatted_address.split(',')[0])
      }
      changeClientCity(response.city)
      changeClientDistrict(response.district)
      changeClientCountry(response.country)
      changeClientPostalCode(response.postal_code)
      changeClientCoordinates({
        latitude: response.lat.toString(),
        longitude: response.lng.toString(),
      })
      setIsChangeAddressModalVisible(false)
      setModalSearchAddress('')
      window.fbq('track', 'SubmitApplication', {
        content_name: 'selected_address',
      })
      changeDeliveryZoneError(false)
    } else {
      const responseMaps = await googleMapsAutocompleteParser({
        lat: response.lat.toString(),
        lng: response.lng.toString(),
      })

      changeClientAddressId(placeId)
      if (responseMaps.city && responseMaps.street) {
        changeClientAddress(`${responseMaps.street}, ${responseMaps.city}`)
      } else if (responseMaps.street) {
        changeClientAddress(responseMaps.street)
      } else if (responseMaps.city) {
        changeClientAddress(responseMaps.city)
      } else {
        changeClientAddress(response.formatted_address.split(',')[0])
      }
      changeClientCity(responseMaps.city)
      changeClientDistrict(responseMaps.district)
      changeClientCountry(responseMaps.country)
      changeClientPostalCode(responseMaps.postal_code)
      changeClientCoordinates({
        latitude: response.lat.toString(),
        longitude: response.lng.toString(),
      })
      setIsChangeAddressModalVisible(false)
      setModalSearchAddress('')
      window.fbq('track', 'SubmitApplication', {
        content_name: 'selected_address',
      })
      changeDeliveryZoneError(false)
    }
    //  else {
    //   changeDeliveryZoneError(true)
    // }
  }
  useEffect(() => {
    if (district && district != '') {
      if (district == 'Madeira') {
        changeClientFromIslands('madeira')
      } else if (district == 'Açores' || district == 'Azores') {
        changeClientFromIslands('açores')
      } else {
        changeClientFromIslands('')
      }
    }
  }, [district])

  const changeAddressModal = () => (
    <Modal
      visible={isChangeAddressModalVisible}
      title="Onde estás?"
      onCancel={() => setIsChangeAddressModalVisible(false)}
      footer={null}
      className="location-modal"
    >
      <MapsPlacesAutocomplete
        address={modalSearchAddress}
        handleSelect={handleSelectAddress}
        handleChange={handleChangeAddress}
        isAddressDeleted={false}
      />
    </Modal>
  )

  useEffect(() => {
    setIsClientLoading(false)
  }, [])

  return (
    <ClientContext.Provider
      value={{
        address,
        latitude,
        longitude,
        city,
        country,
        addressId,
        addressName,
        changeAddressName,
        changeClientAddress,
        changeClientAddressId,
        changeClientCoordinates,
        changeClientCity,
        changeClientCountry,
        isChangeAddressModalVisible,
        showChangeAddressModal,
        changeAddressModal,
        district,
        postalCode,
        changeClientDistrict,
        changeClientPostalCode,
        closePopup,
        isClientLoading,
        updatedInfo,
        changeUpdatedInfo,
        showSpinnerSearchInput,
        changeShowSpinnerSearchInput,
        doingLogin,
        changeDoingLogin,
        addressError,
        isLoading,
        changeIsLoading,
        addressData,
        changeAddressData,
        categorySelected,
        changeCategorySelected,
        deliveryZoneError,
        changeDeliveryZoneError,
        changeAddressError,
        ip,
        changeIp,
        changeIsVisibleAfterHours,
        isVisibleAfterHours,
        changeIsVisibleAfterHoursLoading,
        isVisibleAfterHoursLoading,
        changeClientFromIslands,
        clientFromIslands,
      }}
    >
      {children}
    </ClientContext.Provider>
  )
}

export const useClient = () => useContext(ClientContext)
