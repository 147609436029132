import { isAfter, isBefore } from 'date-fns'

interface VerifyIfProductInPromotionProps {
  specialPrice?: number
  specialPriceBeginDate?: string
  specialPriceEndDate?: string
}

const VerifyIfProductInPromotion = ({
  specialPrice,
  specialPriceBeginDate,
  specialPriceEndDate,
}: VerifyIfProductInPromotionProps) => {
  let haveSpecialPrice = false
  if (specialPrice) {
    if (specialPriceBeginDate || specialPriceEndDate) {
      if (specialPriceBeginDate && specialPriceEndDate) {
        if (
          isAfter(new Date(), new Date(specialPriceBeginDate)) &&
          isBefore(new Date(), new Date(specialPriceEndDate))
        ) {
          haveSpecialPrice = true
        }
      } else if (specialPriceBeginDate && isAfter(new Date(), new Date(specialPriceBeginDate))) {
        haveSpecialPrice = true
      } else if (specialPriceEndDate && isBefore(new Date(), new Date(specialPriceEndDate))) {
        haveSpecialPrice = true
      }
    } else {
      haveSpecialPrice = true
    }
  }
  return haveSpecialPrice
}

export { VerifyIfProductInPromotion }
