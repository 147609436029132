import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { AttributesOptionsProps, AttributesProps } from '@/Props/AttributesProps'
import { ConfigurableProductProps } from '@/Props/ConfigurableProductProps'
import { CategoryProps } from '@/Props/CategoryProps'
import { RetailerProps } from '@/Props/RetailerProps'
import { useRouter } from 'next/router'

interface CategoryProviderProps {
  children: ReactNode
  products: ConfigurableProductProps[]
  category?: CategoryProps
  retailer?: RetailerProps
  attributeOptions: AttributesOptionsProps[]
  menuCategories?: CategoryProps[]
  attributes: AttributesProps[]
}

interface SelectedAttributesOptionsProps {
  attributeId: string
  value: string
}

interface CategoryContextData {
  selectedPage: number
  searchTerm: string
  searchWord: string
  searchWordPage: string
  clearAttributesOptions: boolean
  attributesOptions: AttributesOptionsProps[]
  selectedAttributesOptions: SelectedAttributesOptionsProps[]
  allProducts: ConfigurableProductProps[]
  filteredProducts: ConfigurableProductProps[]
  products: ConfigurableProductProps[]
  selectedCategory: CategoryProps
  menuCategories: CategoryProps[]
  isFilterNavigationActive: boolean
  showPromotionTag: boolean
  changeShowPromotionTag: (value: boolean) => void
  selectedSubCategoires: string
  selectedRetailers: string
  selectedPrice: string
  selectedABV: string
  retailer: RetailerProps
  categoriesMenu: CategoryProps[]
  changeCategoriesMenu: (values: CategoryProps[]) => void
  hasSelectOptionsChangedByClient: boolean
  changeIsFilterNavigationActive: (filterNavigationState: boolean) => void
  changeSearchTerm: (searchTerm: string) => void
  changeSearchWord: (searchWord: string) => void
  changeSearchWordPage: (searchWord: string) => void
  changeClearAttributesOptions: (value: boolean) => void
  changeSelectedPage: (selectedPage: number) => void
  changeFilteredProducts: (allProducts: ConfigurableProductProps[]) => void
  changeProducts: (products: ConfigurableProductProps[]) => void
  changeSelectedOptions: (
    selectedOptions: SelectedAttributesOptionsProps[],
    hasSelectOptionsChangedByClient: boolean
  ) => void
  changeSelectedSubCategories: (selectedSubCategoires: string) => void
  changeSelectedRetailers: (selectedRetailers: string) => void
  changeSelectedPrice: (SelectedPrice: string) => void
  changeSelectedABV: (selectedABV: string) => void
  changeHasSelectOptionsChangedByClient: (hasSelectOptionsChangedByClient: boolean) => void
  attributes: AttributesProps[]
  isSearchPage: boolean
  changeIsSearchPage: (value: boolean) => void
  searchTerms: string[]
  changeSearchTerms: (terms: string[]) => void
  isPromotionCategory: boolean
  changeIsPromotionCategory: (value: boolean) => void
}

interface handleNewUrlProps {
  selectedSubCategoires: string
  selectedRetailers: string
  selectedPrice: string
  selectedABV: string
  selectedAttributesOptions?: string
  selectedPage?: number
  searchTerm: string
}

function arrayEquals(a, b) {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  )
}

export const CategoryContext = createContext({} as CategoryContextData)

export const CategoryProvider = ({
  children,
  category: selectedCategory,
  products: allProducts,
  attributeOptions: attributesOptions,
  menuCategories,
  retailer,
  attributes,
}: CategoryProviderProps) => {
  const router = useRouter()
  const [selectedPage, setSelectedPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const [searchWord, setSearchWord] = useState('')
  const [searchWordPage, setSearchWordPage] = useState('')

  const [selectedAttributesOptions, setSelectedAttributesOptions] = useState<
    SelectedAttributesOptionsProps[]
  >([])
  const [isFilterNavigationActive, setIsFilterNavigationActive] = useState(false)
  const [filteredProducts, setFilteredProducts] = useState<ConfigurableProductProps[]>(allProducts)
  const [selectedSubCategoires, setSelectedSubCategories] = useState('')
  const [selectedRetailers, setSelectedRetailers] = useState('')
  const [selectedPrice, setSelectedPrice] = useState('')
  const [selectedABV, setSelectedABV] = useState('')
  const [isLoadingQueryParams, setIsLoadingQueryParams] = useState(true)
  const [hasSelectOptionsChangedByClient, setHasSelectOptionsChangedByClient] = useState(true)
  const [isSearchPage, setIsSearchPage] = useState(false)
  const [searchTerms, setSearchTerms] = useState<string[]>([])
  const [clearAttributesOptions, setClearAttributesOptions] = useState(false)
  const [isPromotionCategory, setIsPromotionCategory] = useState(false)
  const [products, setProducts] = useState<ConfigurableProductProps[]>([])
  const [showPromotionTag, setShowPromotionTag] = useState(false)
  const [categoriesMenu, setCategoriesMenu] = useState<CategoryProps[]>([])

  // useEffect(() => {
  //   setSearchTerm(searchWord)
  // }, [searchWord])

  const changeCategoriesMenu = (values: CategoryProps[]) => {
    setCategoriesMenu(values)
  }
  const changeShowPromotionTag = (value: boolean) => {
    setShowPromotionTag(value)
  }
  const changeIsPromotionCategory = (value: boolean) => {
    setIsPromotionCategory(value)
  }

  const changeClearAttributesOptions = (value: boolean) => {
    setClearAttributesOptions(value)
  }

  const changeSearchWord = (searchWord: string) => {
    setSearchWord(searchWord)
  }
  const changeSearchWordPage = (searchWord: string) => {
    setSearchWordPage(searchWord)
  }
  const changeSearchTerms = (terms: string[]) => {
    setSearchTerms(terms)
  }
  const changeIsSearchPage = (value: boolean) => {
    setIsSearchPage(value)
  }

  const changeIsFilterNavigationActive = (filterNavigationState: boolean) => {
    setIsFilterNavigationActive(filterNavigationState)
  }

  const changeFilteredProducts = (allProducts: ConfigurableProductProps[]) => {
    setFilteredProducts(allProducts)
  }
  const changeProducts = (products: ConfigurableProductProps[]) => {
    setProducts(products)
  }

  const handleNewUrl = ({
    selectedSubCategoires,
    selectedABV,
    selectedRetailers,
    selectedPrice,
    selectedAttributesOptions,
    selectedPage,
    searchTerm,
  }: handleNewUrlProps) => {
    let alreadyAddedInterregationPoint = false
    let newUrl = router.asPath.split('?')[0]
    if (searchTerm) {
      newUrl += `?q=${searchTerm}`
      alreadyAddedInterregationPoint = true
    }
    if (selectedSubCategoires) {
      if (alreadyAddedInterregationPoint) {
        newUrl += `&subCategories=${selectedSubCategoires}`
      } else {
        newUrl += `?subCategories=${selectedSubCategoires}`
      }
      alreadyAddedInterregationPoint = true
    }
    if (selectedABV) {
      if (alreadyAddedInterregationPoint) {
        newUrl += `&selectedABV=${selectedABV}`
      } else {
        newUrl += `?selectedABV=${selectedABV}`
      }
      alreadyAddedInterregationPoint = true
    }
    if (selectedRetailers) {
      if (alreadyAddedInterregationPoint) {
        newUrl += `&selectedRetailers=${selectedRetailers}`
      } else {
        newUrl += `?selectedRetailers=${selectedRetailers}`
      }
      alreadyAddedInterregationPoint = true
    }
    if (selectedPrice) {
      if (alreadyAddedInterregationPoint) {
        newUrl += `&selectedPrice=${selectedPrice}`
      } else {
        newUrl += `?selectedPrice=${selectedPrice}`
      }
      alreadyAddedInterregationPoint = true
    }
    if (selectedAttributesOptions) {
      if (alreadyAddedInterregationPoint) {
        newUrl += `&selectedAttributesOptions=${selectedAttributesOptions}`
      } else {
        newUrl += `?selectedAttributesOptions=${selectedAttributesOptions}`
      }
      alreadyAddedInterregationPoint = true
    }
    if (selectedPage) {
      if (alreadyAddedInterregationPoint) {
        newUrl += `&selectedPage=${selectedPage}`
      } else {
        newUrl += `?selectedPage=${selectedPage}`
      }
      alreadyAddedInterregationPoint = true
    }

    router.push(newUrl, undefined, { shallow: true })
  }

  const changeSearchTerm = (searchTerm: string) => {
    handleNewUrl({
      selectedSubCategoires,
      selectedABV,
      selectedPrice,
      selectedRetailers,
      selectedPage: 1,
      searchTerm: searchWord,
      selectedAttributesOptions:
        selectedAttributesOptions.length == 0 ? '' : JSON.stringify(selectedAttributesOptions),
    })
    //setSearchTerm(searchTerm)
  }

  const changeSelectedPage = (selectedPage: number) => {
    handleNewUrl({
      selectedSubCategoires,
      selectedABV,
      selectedPrice,
      selectedRetailers,
      selectedPage,
      searchTerm: searchWord,
      selectedAttributesOptions:
        selectedAttributesOptions.length == 0 ? '' : JSON.stringify(selectedAttributesOptions),
    })
    //setSelectedPage(selectedPage)
  }

  const changeSelectedOptions = (
    selectedOptions: SelectedAttributesOptionsProps[],
    hasSelectOptionsChangedByClient: boolean
  ) => {
    if (hasSelectOptionsChangedByClient) {
      handleNewUrl({
        selectedSubCategoires,
        selectedABV,
        selectedPrice,
        selectedRetailers,
        selectedAttributesOptions:
          selectedOptions.length == 0 ? '' : JSON.stringify(selectedOptions),
        selectedPage: 1,
        searchTerm: searchWord,
      })
    }
    /* handleNewUrl({
      selectedSubCategoires,
      selectedABV,
      selectedPrice,
      selectedRetailers,
      selectedAttributesOptions: selectedOptions.length == 0 ? '' : JSON.stringify(selectedOptions),
    }) */
    //setSelectedAttributesOptions(selectedOptions)
  }

  const changeSelectedSubCategories = (selectedSubCategories: string) => {
    handleNewUrl({
      selectedSubCategoires: selectedSubCategories,
      selectedABV,
      selectedPrice,
      selectedRetailers,
      selectedPage: 1,
      searchTerm: searchWord,

      selectedAttributesOptions:
        selectedAttributesOptions.length == 0 ? '' : JSON.stringify(selectedAttributesOptions),
    })

    //setSelectedSubCategories(selectedSubCategories)
  }

  const changeSelectedRetailers = (selectedRetailers: string) => {
    handleNewUrl({
      selectedSubCategoires,
      selectedABV,
      selectedPrice,
      selectedRetailers: selectedRetailers,
      selectedPage: 1,
      searchTerm: searchWord,

      selectedAttributesOptions:
        selectedAttributesOptions.length == 0 ? '' : JSON.stringify(selectedAttributesOptions),
    })
    //setSelectedRetailers(selectedRetailers)
  }

  const changeSelectedPrice = (selectedPrice: string) => {
    handleNewUrl({
      selectedSubCategoires,
      selectedABV,
      selectedPrice,
      selectedRetailers,
      selectedPage: 1,
      searchTerm: searchWord,

      selectedAttributesOptions:
        selectedAttributesOptions.length == 0 ? '' : JSON.stringify(selectedAttributesOptions),
    })
    //setSelectedPrice(selectedPrice)
  }

  const changeSelectedABV = (selectedABV: string) => {
    handleNewUrl({
      selectedSubCategoires,
      selectedABV,
      selectedPrice,
      selectedRetailers,
      selectedPage: 1,
      searchTerm: searchWord,

      selectedAttributesOptions:
        selectedAttributesOptions.length == 0 ? '' : JSON.stringify(selectedAttributesOptions),
    })
    //setSelectedABV(selectedABV)
  }

  const changeHasSelectOptionsChangedByClient = (hasSelectOptionsChangedByClient: boolean) => {
    setHasSelectOptionsChangedByClient(hasSelectOptionsChangedByClient)
  }

  useEffect(() => {
    if (router.isReady) {
      setSelectedSubCategories('')
      setSelectedRetailers('')
      setSelectedABV('')
      setSelectedPrice('')
      setSearchTerm('')
      setSelectedAttributesOptions([])
      setIsLoadingQueryParams(true)
      setFilteredProducts([...allProducts])
      setSelectedPage(1)
      // if (document.querySelector('.product-grid')) {
      //   window.scrollTo({
      //     top: 0,
      //     behavior: 'smooth',
      //   })
      // }
      const {
        subCategories,
        selectedRetailers,
        selectedABV,
        selectedPrice,
        selectedAttributesOptions,
        selectedPage,
        searchTerm,
      } = router.query
      if (subCategories && !Array.isArray(subCategories)) {
        setHasSelectOptionsChangedByClient(true)
        setSelectedSubCategories(subCategories)
      }
      if (selectedRetailers && !Array.isArray(selectedRetailers)) {
        setHasSelectOptionsChangedByClient(true)
        setSelectedRetailers(selectedRetailers)
      }
      if (selectedABV && !Array.isArray(selectedABV)) {
        setHasSelectOptionsChangedByClient(true)
        setSelectedABV(selectedABV)
      }
      if (selectedPrice && !Array.isArray(selectedPrice)) {
        setHasSelectOptionsChangedByClient(true)
        setSelectedPrice(selectedPrice)
      }
      if (selectedAttributesOptions && !Array.isArray(selectedAttributesOptions)) {
        setHasSelectOptionsChangedByClient(true)
        setSelectedAttributesOptions(JSON.parse(selectedAttributesOptions))
      }
      if (selectedPage && !Array.isArray(selectedPage)) {
        setHasSelectOptionsChangedByClient(true)
        setSelectedPage(Number(selectedPage))
      }
      if (searchTerm && !Array.isArray(searchTerm)) {
        setHasSelectOptionsChangedByClient(true)
        setSearchTerm(searchTerm)
      }
      setIsLoadingQueryParams(false)
    }
  }, [router, isLoadingQueryParams, allProducts])

  /* useEffect(() => {
    if (
      searchTerm == '' &&
      selectedAttributesOptions.length == 0 &&
      selectedSubCategoires == '' &&
      selectedRetailers == '' &&
      selectedPrice == '' &&
      selectedABV == ''
    ) {
      setFilteredProducts(allProducts)
      return
    }
    const searchProductsResult = [...filteredProducts].filter((product) => {
      if (product.name.toLowerCase().includes(searchTerm.toLowerCase())) {
        return true
      }

      const simpleProductsFinded = product.simpleProducts.filter((simpleProduct) => {
        if (simpleProduct.ean.search(searchTerm.toLowerCase()) > -1) {
          return true
        }
      })

      if (simpleProductsFinded.length > 0) {
        return true
      }
    })
    if (!arrayEquals(filteredProducts, searchProductsResult)) {
      setFilteredProducts(searchProductsResult)
    }
  }, [
    allProducts,
    filteredProducts,
    searchTerm,
    selectedABV,
    selectedAttributesOptions.length,
    selectedPrice,
    selectedRetailers,
    selectedSubCategoires,
  ]) */

  /* useEffect(() => {
    if (!isLoadingQueryParams) {
      const { subCategories } = router.query
      let newUrl = router.asPath.split('?')[0]
      if (
        searchTerm == '' &&
        selectedAttributesOptions.length == 0 &&
        selectedSubCategoires == '' &&
        selectedRetailers == '' &&
        selectedPrice == '' &&
        selectedABV == ''
      ) {
        if (newUrl != router.asPath) {
          //router.push(newUrl)
          setFilteredProducts(allProducts)
          setSelectedSubCategories('')
          router.replace(newUrl, undefined, { shallow: true })
          console.log('Checkou aqui')

          return
        }
        return
      }

      let alreadyAddedInterregationPoint = false
      if (selectedSubCategoires) {
        newUrl += `?subCategories=${selectedSubCategoires}`
        alreadyAddedInterregationPoint = true
      } else if (selectedSubCategoires == '') {
        //setHasSelectOptionsChangedByClient(true)
        //setSelectedSubCategories('')
      }
      if (newUrl != router.asPath) {
        router.replace(newUrl, undefined, { shallow: true })
        //router.push(newUrl)
        return
      }
    }
    return
    const searchProductsResult = [...filteredProducts].filter((product) => {
      if (product.name.toLowerCase().includes(searchTerm.toLowerCase())) {
        return true
      }

      const simpleProductsFinded = product.simpleProducts.filter((simpleProduct) => {
        if (simpleProduct.ean.search(searchTerm.toLowerCase()) > -1) {
          return true
        }
      })

      if (simpleProductsFinded.length > 0) {
        return true
      }
    })
    if (!arrayEquals(filteredProducts, searchProductsResult)) {
      setFilteredProducts(searchProductsResult)
    }
  }, [
    searchTerm,
    filteredProducts,
    allProducts,
    selectedAttributesOptions,
    selectedSubCategoires,
    selectedRetailers,
    selectedPrice,
    selectedABV,
    isLoadingQueryParams,
    router,
  ]) */

  return (
    <CategoryContext.Provider
      value={{
        selectedPage,
        searchTerm,
        searchWord,
        changeSearchWord,
        attributesOptions,
        selectedAttributesOptions,
        allProducts,
        filteredProducts,
        selectedCategory,
        menuCategories,
        isFilterNavigationActive,
        changeIsFilterNavigationActive,
        changeSearchTerm,
        changeSelectedPage,
        changeFilteredProducts,
        changeSelectedOptions,
        changeSelectedSubCategories,
        changeSelectedRetailers,
        changeSelectedPrice,
        changeSelectedABV,
        selectedSubCategoires,
        selectedRetailers,
        selectedPrice,
        selectedABV,
        retailer,
        hasSelectOptionsChangedByClient,
        changeHasSelectOptionsChangedByClient,
        attributes,
        isSearchPage,
        changeIsSearchPage,
        searchTerms,
        changeSearchTerms,
        changeClearAttributesOptions,
        clearAttributesOptions,
        isPromotionCategory,
        changeIsPromotionCategory,
        changeProducts,
        products,
        showPromotionTag,
        changeShowPromotionTag,
        searchWordPage,
        changeSearchWordPage,
        categoriesMenu,
        changeCategoriesMenu,
      }}
    >
      {children}
    </CategoryContext.Provider>
  )
}

export const useCategory = () => useContext(CategoryContext)
