import axios from 'axios'
import { geocodeByPlaceId } from 'react-places-autocomplete'
import { GoogleMapsSelection } from './../Props/GoogleMapsSelection'
interface Props {
  placeId?: string
  lat?: string
  lng?: string
}
const googleMapsAutocompleteParser = async ({
  placeId,
  lat,
  lng,
}: Props): Promise<GoogleMapsSelection> => {
  if (!lat && !lng) {
    const results = await geocodeByPlaceId(placeId).catch((error) => {
      console.error('Error', error)
    })

    const { long_name: address = '' } =
      results[0].address_components.find((c) => c.types.includes('route')) || {}

    const { long_name: city = '' } =
      results[0].address_components.find((c) => c.types.includes('locality')) || {}

    const { long_name: district = '' } =
      results[0].address_components.find((c) => c.types.includes('administrative_area_level_1')) ||
      {}

    const { long_name: postalCode = '' } =
      results[0].address_components.find((c) => c.types.includes('postal_code')) || {}

    const { long_name: country = '' } =
      results[0].address_components.find((c) => c.types.includes('country')) || {}

    return {
      valid: true,
      formatted_address: results[0].formatted_address,
      place_id: placeId,
      lat: results[0].geometry.location.lat(),
      lng: results[0].geometry.location.lng(),
      street: address,
      postal_code: postalCode,
      city: city,
      district: district,
      country: country,
    }
  } else {
    const responseMaps = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${
        process.env.NEXT_PUBLIC_NODE_ENV == 'production'
          ? 'AIzaSyAatCftTIoLayeA9K0KQ6BR8U3ACLeGKvg'
          : 'AIzaSyCzROWBTjHhm1XSsi70-QEGUiczHte1uGA'
      }`
    )
    const { long_name: address = '' } =
      responseMaps.data.results[0].address_components.find((c) => c.types.includes('route')) || {}

    const { long_name: city = '' } =
      responseMaps.data.results[0].address_components.find((c) => c.types.includes('locality')) ||
      {}

    const { long_name: district = '' } =
      responseMaps.data.results[0].address_components.find((c) =>
        c.types.includes('administrative_area_level_1')
      ) || {}

    const { long_name: postalCode = '' } =
      responseMaps.data.results[0].address_components.find((c) =>
        c.types.includes('postal_code')
      ) || {}

    const { long_name: country = '' } =
      responseMaps.data.results[0].address_components.find((c) => c.types.includes('country')) || {}

    return {
      valid: true,
      formatted_address: responseMaps.data.results[0].formatted_address,
      place_id: placeId,
      lat: responseMaps.data.results[0].geometry.location.lat,
      lng: responseMaps.data.results[0].geometry.location.lng,
      street: address,
      postal_code: postalCode,
      city: city,
      district: district,
      country: country,
    }
  }
}

export default googleMapsAutocompleteParser
