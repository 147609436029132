import React, { createContext, useState, useContext, useEffect, useCallback } from 'react'
import Cookies from 'js-cookie'
import api from '@/services/api'
import { CartProductInfoProps } from '@/Props/CartProductInfoProps'
import { AutoDestroyModal } from '@/components/shared/AutoDestroyModal'
import { useClient } from './ClientContext'
import { CartProductsGroupByShipping } from '@/Props/CartProductsGroupByShipping'
import { MultiVendorCartItemProps, MultiVendorCartProps } from '@/Props/MultiVendorCartProps'
import { useSession } from 'next-auth/client'
import { CartProps } from '@/Props/CartProps'
import { VerifyIfProductInPromotion } from '@/utils/VerifyIfProductInPromotion'
import * as Sentry from '@sentry/nextjs'
import TagManager from 'react-gtm-module'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { CartProductInfoGTM } from '@/pages/produto/[id]'
import { useRouter } from 'next/router'
import { browserName, browserVersion, osName, osVersion } from 'react-device-detect'
export interface infoForKlavyio {
  url?: string
  slug?: string
  id?: string
  name?: string
  price?: string
  finalPrice?: string
  image?: string
  categories?: string
  category?: string
  parentCategory?: string
  quantity?: string
  message?: string
  type?: string
  product_type?: string
  size?: string
  retailer?: string
}

interface CalculateShippingPricesReturn {
  totalShippingPrice: number
  shippingPriceWithoutPromotion: number
  carts: CartProductsGroupByShipping[]
}

export type MultiVendorCartContextType = {
  isLoading: boolean
  cartId: string
  //changeCartId(id: number): void
  cartProducts: MultiVendorCartItemProps[]
  promoCode: string
  mbwayPhone: string
  changeMbwayPhone(number: string): void
  promoCodeDiscount: number
  //changePromoCode(promoCode: string): void
  cartNote: string
  errorCart: boolean
  errorShippingPrice: boolean
  changeErrorShippingPrice(value: boolean): void
  fromEmail: boolean
  changeFromEmail(value: boolean): void
  //changeCartNote(cartNote: string): void
  isPresent: boolean
  totalItems(): number
  isMiniCartVisible: boolean
  productInfo: CartProductInfoProps
  isAddingToCart: boolean
  cartCrossSells: CartProductInfoProps[]
  showPopUpDifferentRetailer: boolean
  changeCartProducts(products: MultiVendorCartItemProps[]): void
  changeShowPopUpDifferentRetailer(value: boolean): void
  changeCartId(id: string): void
  calculateShippingPrices: (
    newProductsAvailable: CartProductInfoProps[],
    clientFromIslands: string
  ) => Promise<CalculateShippingPricesReturn>
  // crossSellProducts: CartProductInfoProps[]
  // changeCrossSellProducts: (crossSellProducts: CartProductInfoProps[]) => void

  //changeIsPresent(isPresent: boolean): void
  updateItemQty(cartItem: MultiVendorCartItemProps): void
  addItemToCart(cartItem: MultiVendorCartItemProps): void
  removeItemFromCart(itemId): void
  getCartProductsInformation(): Promise<CartProductInfoProps[]>
  getCartCrossSells(): Promise<CartProductInfoProps[]>
  getSubTotalPrice(cartItems: CartProductInfoProps[]): number
  changePromoCode(promoCode: string): void
  changePromoCodeDiscount(promoCode: number): void
  changeCartNote(cartNote: string): void
  changeIsPresent(isPresent: boolean): void
  validateCart(
    cartItems: CartProductInfoProps[],
    returnJustAvailabel?: boolean
  ): Promise<CartProductInfoProps[]>
  removeItems(cartItems: CartProductInfoProps[]): Promise<boolean>
  clearCart(): void
}

const MultiVendorCartContext = createContext({} as MultiVendorCartContextType)

export const MultiVendorCartProvider = ({ children }) => {
  const [session, sessionLoading] = useSession()
  const [isLoading, setIsLoading] = useState(true)
  const [cartId, setCartId] = useState(Cookies.get('cart-id'))
  const [cartProducts, setCartProducts] = useState<MultiVendorCartItemProps[]>(
    Cookies.get('cart') ? JSON.parse(Cookies.get('cart')) : []
  )
  const [promoCode, setPromoCode] = useState(Cookies.get('promo-code'))
  const [promoCodeDiscount, setPromoCodeDiscount] = useState(0)
  const [cartNote, setCartNote] = useState(Cookies.get('cart-note'))
  const [isPresent, setIsPresent] = useState(Cookies.get('cart-present') ? true : false)
  const [isMiniCartVisible, setIsMiniCartVisible] = useState(false)
  const [productInfo, setProductInfo] = useState<CartProductInfoProps>()
  const [isAddingToCart, setIsAddingToCart] = useState(false)
  const { latitude, longitude, address, addressId } = useClient()
  const [errorMessage, setErrorMessage] = useState('')
  const [alreadyOpen, setAlreadyOpen] = useState(false)
  const [cartCrossSells, setCartCrossSells] = useState<CartProductInfoProps[]>([])
  const sendDataToGTM = useGTMDispatch()
  const [errorShippingPrice, setErrorShippingPrice] = useState(false)
  const [showPopUpDifferentRetailer, setShowPopUpDifferentRetailer] = useState(false)
  const [fromEmail, setFromEmail] = useState(false)
  const [mbwayPhone, setMbwayPhone] = useState('')
  const [errorCart, setErrorCart] = useState(false)
  const router = useRouter()
  const changeMbwayPhone = (number: string) => {
    setMbwayPhone(number)
  }

  useEffect(() => {
    setIsLoading(false)
  }, [])

  const createNewCart = async () => {
    const { data: cart } = await api.post<CartProps>('/carts', {
      customer_id: session.customer.id,
      address: address,
      retailer_id: null,
      address_id: addressId,
    })
    Cookies.set('cartid', cart.id, { expires: 60 })
    setCartId(cart.id)
    //console.log('cartid', cart.id)
    return cart.id
  }

  const changeErrorShippingPrice = (value: boolean) => {
    setErrorShippingPrice(value)
  }

  useEffect(() => {
    if (sessionLoading && session) {
      try {
        api.defaults.headers.Authorization = `Bearer ${session.accessToken}`
        api
          .get<MultiVendorCartProps>(`/customers/${session.customer.id}/cart`)
          .then((response) => {
            if (response.data.id) {
              if (response.data.id == cartId) {
                setCartId(response.data.id)
                Cookies.set('cart-id', response.data.id, { expires: 60 })
                if (
                  cartProducts &&
                  cartProducts.length == 0 &&
                  response.data.items &&
                  response.data.items.length > 0
                ) {
                  //console.log('items', response.data.items)
                  //const newCartProducts: MultiVendorCartItemProps[] = []
                  const newCartProducts = response.data.items.map(
                    (item): MultiVendorCartItemProps => {
                      return {
                        retailerProductId: item.retailer_product_id,
                        shippingDay: item.shipping_day,
                        shippingType: item.shipping_type,
                        shippingTime: item.shipping_time,
                        qty: item.qty,
                        retailerId: item.retailer_id,
                        isAfterHoursProduct: item.after_hours_product,
                      }
                    }
                  )
                  if (cartProducts.length > 0) {
                    setCartProducts([...cartProducts, ...newCartProducts])
                  } else {
                    setCartProducts(newCartProducts)
                  }
                }
              } else {
                setCartId(response.data.id)
                Cookies.set('cart-id', response.data.id, { expires: 60 })
                setCartProducts(Cookies.get('cart') ? JSON.parse(Cookies.get('cart')) : [])
              }
            } else {
              if (address && address !== '') {
                createNewCart()
              }
            }
          })
          .catch((error) => {
            Sentry.captureException(error)
            // console.log('error 138', error)
          })
      } catch (error) {
        Sentry.captureException(error)
        // console.log('error 141', error)
      }
    }
  }, [session, sessionLoading])

  useEffect(() => {
    if (cartId) {
      Cookies.set('cart-id', cartId, { expires: 60 })
    } else {
      Cookies.remove('cart-id')
    }
  }, [cartId])

  useEffect(() => {
    Cookies.set('cart', JSON.stringify(cartProducts), { expires: 60 })
  }, [cartProducts])

  useEffect(() => {
    if (isMiniCartVisible) {
      setTimeout(() => {
        setIsMiniCartVisible(false)
      }, 5000)
    }
  }, [isMiniCartVisible])

  useEffect(() => {
    if (alreadyOpen) {
      setTimeout(() => {
        setAlreadyOpen(alreadyOpen ? false : true)
      }, 2000)
    }
  }, [alreadyOpen])

  const totalItems = (): number => {
    const reducer = (accumulator: number, currentValue: number) => accumulator + currentValue

    return cartProducts.map((el) => el.qty).reduce(reducer, 0)
  }

  const showModalDifferentRetailer = (cartItem: MultiVendorCartItemProps) => {
    Modal.confirm({
      visible: showPopUpDifferentRetailer,
      icon: <ExclamationCircleOutlined style={{ color: '#F2C94C' }} />,
      title: `Produto de outra loja`,
      content:
        'O produto adicionado é de outra loja e terá impacto na taxa de transporte. Continuar?',
      okText: 'Sim, adicionar',
      cancelText: 'Cancelar',
      okButtonProps: { style: { color: 'white', backgroundColor: '#F16749' } },
      cancelButtonProps: { style: { color: '#F16749', border: 'none' } },
      onCancel() {
        setShowPopUpDifferentRetailer(false)
      },
      onOk() {
        setShowPopUpDifferentRetailer(false)
        addItemToCart(cartItem, true)
      },
    })
  }

  const addItemToCart = async (cartItem: MultiVendorCartItemProps, continueAdding = false) => {
    const productsCart = await getCartProductsInformation()
    const findIndex = productsCart.findIndex((each) => each.retailerId != cartItem.retailerId)
    const findEqualIndex = productsCart.findIndex(
      (each) => Number(each.retailerId) == Number(cartItem.retailerId)
    )
    if (findIndex != -1 && !continueAdding && findEqualIndex == -1) {
      setShowPopUpDifferentRetailer(true)
      showModalDifferentRetailer(cartItem)
    } else {
      setIsAddingToCart(true)
      const findedProductIndex = cartProducts.findIndex(
        (item) =>
          item.retailerProductId == cartItem.retailerProductId &&
          item.shippingType == cartItem.shippingType &&
          item.shippingTime == cartItem.shippingTime
      )

      const productExist = findedProductIndex != -1
      let cartQty = 0
      cartProducts.map((product) => {
        if (product.retailerProductId == cartItem.retailerProductId) {
          cartQty += product.qty
        }
        if (cartItem.isAfterHoursProduct) {
          product.isAfterHoursProduct = true
        }
      })

      const { data: validatedProduct } = await api.post(`carts/validateStock`, {
        //receber stock
        cartItem: { ...cartItem, qty: cartQty + cartItem.qty },
      })

      if (validatedProduct && validatedProduct.canAddToCart) {
        api
          .post<CartProductInfoGTM>(`/carts/product-info-gtm`, {
            cartItem,
          })
          .then(({ data }) => {
            sendDataToGTM({ ecommerce: null })
            sendDataToGTM({
              event: 'addToCart',
              ecommerce: {
                currencyCode: 'EUR',
                add: {
                  products: [
                    {
                      name: data.name,
                      id: data.id,
                      price: data.price,
                      brand: data.brand,
                      category: data.category,
                      variant: data.variant,
                      quantity: data.quantity,
                    },
                  ],
                },
              },
            })
            let email = Cookies.get('clientEmail')
            let name = Cookies.get('clientName')
            if (!email && session && session.customer && session.customer.email) {
              email = session.customer.email
              name = session.customer.name
            }
            if (email || (session && session.customer && session.customer.email)) {
              const prodInfo: infoForKlavyio = {
                id: data.id,
                name: data.name,
                image: data.image,
                finalPrice: data.klavFinalPrice,
                price: data.klavPrice,
                slug: data.slug,
                url: process.env.NEXT_PUBLIC_WEBSITE_URL + router.asPath,
                categories: data.categories,
                category: data.category,
                parentCategory: data.parentCategory,
                quantity: data.quantity.toString(),
                product_type: data.product_type,
                size: data.size,
                type: data.type,
                retailer: data.retailer,
              }
              api.post(`add-to-cart`, {
                email: email,
                name: name ? name : '',
                browser: `${browserName} v${browserVersion}`,
                os: `${osName} v${osVersion}`,
                product: prodInfo,
              })
            }
            window.fbq('track', 'AddToCart', {
              currency: 'EUR',
              contents: [
                {
                  id: data.name,
                  quantity: data.quantity,
                },
              ],
              content_type: 'product',
            })
          })
        if (session && session.customer) {
          // console.log('a', cartId)
          if (cartId) {
            try {
              await api.post(`/carts/${cartId}/items`, {
                retailer_product_id: cartItem.retailerProductId,
                qty: productExist
                  ? cartProducts[findedProductIndex].qty + cartItem.qty
                  : cartItem.qty,
                cart_id: cartId,
                retailer_id: cartItem.retailerId,
                shipping_day: cartItem.shippingDay,
                shipping_type: cartItem.shippingType,
                shipping_time: cartItem.shippingTime,
                afterHoursProduct: cartItem.isAfterHoursProduct ? true : false,
              })
              await api.put<CartProps>(`/carts/${cartId}`, {
                is_abandoned: true,
              })
            } catch (error) {
              Sentry.captureException(error)
              const cartid = await createNewCart()
              try {
                await api.post(`/carts/${cartid}/items`, {
                  retailer_product_id: cartItem.retailerProductId,
                  qty: productExist
                    ? cartProducts[findedProductIndex].qty + cartItem.qty
                    : cartItem.qty,
                  cart_id: cartid,
                  retailer_id: cartItem.retailerId,
                  shipping_day: cartItem.shippingDay,
                  shipping_type: cartItem.shippingType,
                  shipping_time: cartItem.shippingTime,
                  afterHoursProduct: cartItem.isAfterHoursProduct ? true : false,
                })
                await api.put<CartProps>(`/carts/${cartid}`, {
                  is_abandoned: true,
                })
              } catch (error) {
                //console.log('erro', cartid, error)
                Sentry.captureException(error)
                //createNewCart()
                //console.log('error adding item to cart line 219')
              }
              //createNewCart()
              //console.log('error adding item to cart line 219')
            }
          } else {
            // console.log('c')
            const cartid = await createNewCart()
            try {
              await api.post(`/carts/${cartid}/items`, {
                retailer_product_id: cartItem.retailerProductId,
                qty: productExist
                  ? cartProducts[findedProductIndex].qty + cartItem.qty
                  : cartItem.qty,
                cart_id: cartid,
                retailer_id: cartItem.retailerId,
                shipping_day: cartItem.shippingDay,
                shipping_type: cartItem.shippingType,
                shipping_time: cartItem.shippingTime,
                afterHoursProduct: cartItem.isAfterHoursProduct ? true : false,
              })
              await api.put<CartProps>(`/carts/${cartid}`, {
                is_abandoned: true,
              })
            } catch (error) {
              //console.log('erro', cartid, error)
              Sentry.captureException(error)
              //createNewCart()
              //console.log('error adding item to cart line 219')
            }
          }
        }

        if (productExist) {
          const newCartProducts = [...cartProducts]
          if (validatedProduct && validatedProduct.canAddToCart) {
            newCartProducts[findedProductIndex].qty += cartItem.qty
            setCartProducts(newCartProducts)
            if (newCartProducts) {
              const response = await api.post<CartProductInfoProps[]>('/carts/cross-sells', {
                cartProducts: newCartProducts,
              })
              setCartCrossSells(response.data)
            }
          }
        } else {
          if (validatedProduct && validatedProduct.canAddToCart) {
            const products = [...cartProducts, cartItem]
            setCartProducts(products)
            if (products) {
              const response = await api.post<CartProductInfoProps[]>('/carts/cross-sells', {
                cartProducts: products,
              })
              setCartCrossSells(response.data)
            }
          }
        }

        setIsMiniCartVisible(true)
        //     setCrossSellsProducts(crossSellProducts)
        // const response = await api.post<CartProductInfoProps[]>('/carts/cross-sells', {
        //   cartProducts,
        // })
        // console.log("response2", response)
        // setCrossSells(response.data)
      } else {
        let modelMessage
        // console.log('findedProductIndex', findedProductIndex)
        if (findedProductIndex != -1) {
          const productsToCompleteStock =
            validatedProduct.stock - cartProducts[findedProductIndex].qty
          modelMessage =
            productsToCompleteStock <= 0
              ? `Não temos a quantidade pedida em stock, podes apenas adicionar ${
                  validatedProduct.stock
                } ${validatedProduct.stock == 1 ? 'produto' : 'produtos'}`
              : `Não temos a quantidade pedida em stock, podes apenas adicionar mais ${productsToCompleteStock} ${
                  productsToCompleteStock == 1 ? 'produto' : 'produtos'
                }`
        } else {
          modelMessage = `Não temos a quantidade pedida em stock, podes apenas adicionar ${
            validatedProduct.stock
          } ${validatedProduct.stock == 1 ? 'produto' : 'produtos'}`
        }
        AutoDestroyModal({
          type: 'error',
          title: 'Erro ao adicionar o produto ao carrinho',
          content: modelMessage,
          secondsToClose: 5,
        })
        api
          .post<CartProductInfoGTM>(`/carts/product-info-gtm`, {
            cartItem,
          })
          .then(({ data }) => {
            sendDataToGTM({ ecommerce: null })
            sendDataToGTM({
              event: 'addToCart',
              ecommerce: {
                currencyCode: 'EUR',
                add: {
                  products: [
                    {
                      name: data.name,
                      id: data.id,
                      price: data.price,
                      brand: data.brand,
                      category: data.category,
                      variant: data.variant,
                      quantity: data.quantity,
                    },
                  ],
                },
              },
            })
            let email = Cookies.get('clientEmail')
            let name = Cookies.get('clientName')
            if (!email && session && session.customer && session.customer.email) {
              email = session.customer.email
              name = session.customer.name
            }
            if (email || (session && session.customer && session.customer.email)) {
              const prodInfo: infoForKlavyio = {
                id: data.id,
                name: data.name,
                image: data.image,
                finalPrice: data.klavFinalPrice,
                price: data.klavPrice,
                slug: data.slug,
                url: process.env.NEXT_PUBLIC_WEBSITE_URL + router.asPath,
                categories: data.categories,
                category: data.category,
                parentCategory: data.parentCategory,
                quantity: data.quantity.toString(),
                message: modelMessage,
                product_type: data.product_type,
                size: data.size,
                type: data.type,
                retailer: data.retailer,
              }
              api.post(`add-to-cart`, {
                email: email,
                name: name ? name : '',
                browser: `${browserName} v${browserVersion}`,
                os: `${osName} v${osVersion}`,
                product: prodInfo,
              })
            }
            window.fbq('track', 'AddToCart', {
              currency: 'EUR',
              contents: [
                {
                  id: data.name,
                  quantity: data.quantity,
                },
              ],
              content_type: 'product',
            })
          })
      }
      setIsAddingToCart(false)
    }
  }
  const removeItemFromCart = async (cartItem: MultiVendorCartItemProps) => {
    const findedProductIndex = cartProducts.findIndex(
      (item) =>
        item.retailerProductId == cartItem.retailerProductId &&
        item.shippingType == cartItem.shippingType &&
        item.shippingTime == cartItem.shippingTime &&
        item.shippingDay == cartItem.shippingDay
    )

    const productExist = findedProductIndex != -1
    if (productExist) {
      cartProducts.splice(findedProductIndex, 1)
      setCartProducts([...cartProducts])
    }
    api
      .post<CartProductInfoGTM>(`/carts/product-info-gtm`, {
        cartItem,
      })
      .then(({ data }) => {
        sendDataToGTM({ ecommerce: null })
        sendDataToGTM({
          event: 'removeFromCart',
          ecommerce: {
            remove: {
              products: [
                {
                  name: data.name,
                  id: data.id,
                  price: data.price,
                  brand: data.brand,
                  category: data.category,
                  variant: data.variant,
                  quantity: data.quantity,
                },
              ],
            },
          },
        })
        let email = Cookies.get('clientEmail')
        let name = Cookies.get('clientName')
        if (!email && session && session.customer && session.customer.email) {
          email = session.customer.email
          name = session.customer.name
        }
        if (email || (session && session.customer && session.customer.email)) {
          const prodInfo: infoForKlavyio = {
            id: data.id,
            name: data.name,
            image: data.image,
            finalPrice: data.klavFinalPrice,
            price: data.klavPrice,
            slug: data.slug,
            url: process.env.NEXT_PUBLIC_WEBSITE_URL + router.asPath,
            categories: data.categories,
            category: data.category,
            parentCategory: data.parentCategory,
            quantity: data.quantity.toString(),
            product_type: data.product_type,
            size: data.size,
            type: data.type,
            retailer: data.retailer,
          }
          api.post(`remove-from-cart`, {
            email: email,
            name: name ? name : '',
            browser: `${browserName} v${browserVersion}`,
            os: `${osName} v${osVersion}`,
            product: prodInfo,
          })
        }
      })
    if (session && cartId && cartItem.retailerProductId) {
      try {
        api.delete(`/carts/${cartId}/items/${cartItem.retailerProductId}`).catch((error) => {
          Sentry.captureException(error)
          return false
          // console.log('Error apagar produto')
        })
        if (cartProducts.length < 1) {
          changePromoCode(null)
        }
        if (cartProducts.length > 0) {
          await api.put<CartProps>(`/carts/${cartId}`, {
            is_abandoned: true,
          })
        } else {
          await api.put<CartProps>(`/carts/${cartId}`, {
            is_abandoned: false,
          })
        }
        const newCrossSells = await getCartCrossSells()
        setCartCrossSells(newCrossSells)
      } catch (error) {
        Sentry.captureException(error)

        // console.log('Error apagar produto')
        return false
      }
    } else {
      return false
    }
  }

  const getCartProductsInformation = async () => {
    const response = await api.post<CartProductInfoProps[]>('/carts/items-info', {
      cartProducts,
    })
    return response.data
  }

  const getCartCrossSells = async () => {
    const response = await api.post<CartProductInfoProps[]>('/carts/cross-sells', {
      cartProducts,
    })
    return response.data
  }

  const clearCart = () => {
    Cookies.remove('cart')
    Cookies.remove('cart-retailer-id')
    Cookies.remove('cart-shipping-type')
    Cookies.remove('cart-shipping-time')
    Cookies.remove('cart-shipping-day')
    Cookies.remove('promo-code')
    Cookies.remove('cart-note')
    Cookies.remove('cart-present')
    //Cookies.remove('cart-id')
    //setItems([])
    setPromoCode(null)
    setPromoCodeDiscount(0)
    setCartNote('')
    setIsPresent(false)
    //setCartId('')
  }

  const validateCart = async (
    cartProductInfo: CartProductInfoProps[],
    returnJustAvailabel = true
  ) => {
    const newProductsAvailable: CartProductInfoProps[] = []
    // console.log('latidude', latitude, longitude)
    const newLatitude = latitude ? latitude : 0
    const newLongitude = longitude ? longitude : 0

    try {
      const { data } = await api.post(
        `carts/validate?latitude=${newLatitude}&longitude=${newLongitude}`,
        {
          cartProducts: cartProductInfo,
        }
      )
      data.validatedProducts.map((product) => {
        if (product.canDoCheckout || !returnJustAvailabel) {
          newProductsAvailable.push(product)
        }
      })
    } catch (error) {
      setErrorCart(true)
    }
    return newProductsAvailable
  }

  const getSubTotalPrice = (cartItems: CartProductInfoProps[]) => {
    let totalPrice = 0
    cartItems.map((cartItem) => {
      const isInPromotion = VerifyIfProductInPromotion({
        specialPrice: cartItem.special_price,
        specialPriceBeginDate: cartItem.special_price_begin_date,
        specialPriceEndDate: cartItem.special_price_end_date,
      })
      if (isInPromotion) {
        totalPrice += cartItem.qty * Number(cartItem.special_price)
      } else {
        totalPrice += cartItem.qty * Number(cartItem.price)
      }
    })
    return totalPrice
  }

  const updateItemQty = async (cartItem: MultiVendorCartItemProps) => {
    const findedProductIndex = cartProducts.findIndex(
      (item) =>
        item.retailerProductId == cartItem.retailerProductId &&
        item.shippingType == cartItem.shippingType &&
        item.shippingTime == cartItem.shippingTime &&
        item.shippingDay == cartItem.shippingDay
    )
    const productExist = findedProductIndex != -1
    let cartQty = cartItem.qty
    cartProducts.map((product, index) => {
      if (product.retailerProductId == cartItem.retailerProductId && index != findedProductIndex) {
        cartQty += product.qty
      }
    })

    const { data: validatedProduct } = await api.post(`carts/validateStock`, {
      cartItem: {
        ...cartItem,
        qty: cartQty,
      },
    })
    if (productExist) {
      const newCartProducts = cartProducts
      if (validatedProduct && validatedProduct.canAddToCart) {
        newCartProducts[findedProductIndex].qty = cartItem.qty
        setCartProducts([...newCartProducts])
        try {
          await api.put(`/carts/${cartId}/items/${cartItem.retailerProductId}`, {
            qty: cartItem.qty,
          })
          await api.put<CartProps>(`/carts/${cartId}`, {
            is_abandoned: true,
          })
        } catch (error) {
          Sentry.captureException(error)
          //createNewCart()
          //console.log('error adding item to cart line 219')
        }
        api
          .post<CartProductInfoGTM>(`/carts/product-info-gtm`, {
            cartItem,
          })
          .then(({ data }) => {
            let email = Cookies.get('clientEmail')
            let name = Cookies.get('clientName')
            if (!email && session && session.customer && session.customer.email) {
              email = session.customer.email
              name = session.customer.name
            }
            if (email || (session && session.customer && session.customer.email)) {
              const prodInfo: infoForKlavyio = {
                id: data.id,
                name: data.name,
                image: data.image,
                finalPrice: data.klavFinalPrice,
                price: data.klavPrice,
                slug: data.slug,
                url: process.env.NEXT_PUBLIC_WEBSITE_URL + router.asPath,
                categories: data.categories,
                category: data.category,
                parentCategory: data.parentCategory,
                quantity: data.quantity.toString(),
                retailer: data.retailer,
                size: data.size,
                type: data.type,
                product_type: data.product_type,
              }
              api.post(`update-qty-cart`, {
                email: email,
                name: name ? name : '',
                browser: `${browserName} v${browserVersion}`,
                os: `${osName} v${osVersion}`,
                product: prodInfo,
              })
            }
          })
      } else {
        setCartProducts([...cartProducts])
        let modelMessage
        // console.log('findedProductIndex', findedProductIndex)
        if (findedProductIndex != -1) {
          const productsToCompleteStock =
            validatedProduct.stock - cartProducts[findedProductIndex].qty
          modelMessage =
            productsToCompleteStock <= 0
              ? `Não temos a quantidade pedida em stock, podes apenas adicionar ${
                  validatedProduct.stock
                } ${validatedProduct.stock == 1 ? 'produto' : 'produtos'}`
              : `Não temos a quantidade pedida em stock, podes apenas adicionar mais ${productsToCompleteStock} ${
                  productsToCompleteStock == 1 ? 'produto' : 'produtos'
                }`
        } else {
          modelMessage = `Não temos a quantidade pedida em stock, podes apenas adicionar ${
            validatedProduct.stock
          } ${validatedProduct.stock == 1 ? 'produto' : 'produtos'}`
        }

        if (!alreadyOpen) {
          AutoDestroyModal({
            type: 'error',
            title: 'Erro ao adicionar o produto ao carrinho',
            content: modelMessage,
            secondsToClose: 5,
          })
          setAlreadyOpen(true)
        }
        api
          .post<CartProductInfoGTM>(`/carts/product-info-gtm`, {
            cartItem,
          })
          .then(({ data }) => {
            let email = Cookies.get('clientEmail')
            let name = Cookies.get('clientName')
            if (!email && session && session.customer && session.customer.email) {
              email = session.customer.email
              name = session.customer.name
            }
            if (email || (session && session.customer && session.customer.email)) {
              const prodInfo: infoForKlavyio = {
                id: data.id,
                name: data.name,
                image: data.image,
                finalPrice: data.klavFinalPrice,
                price: data.klavPrice,
                slug: data.slug,
                url: process.env.NEXT_PUBLIC_WEBSITE_URL + router.asPath,
                categories: data.categories,
                category: data.category,
                parentCategory: data.parentCategory,
                quantity: data.quantity.toString(),
                message: modelMessage,
                size: data.size,
                type: data.type,
                product_type: data.product_type,
                retailer: data.retailer,
              }
              api.post(`update-qty-cart`, {
                email: email,
                name: name ? name : '',
                browser: `${browserName} v${browserVersion}`,
                os: `${osName} v${osVersion}`,
                product: prodInfo,
              })
            }
          })
      }
    }
  }

  const removeItems = async (items: MultiVendorCartItemProps[]): Promise<boolean> => {
    const cartItems: MultiVendorCartItemProps[] = [...items]
    for await (const cartItem of items) {
      const cartItemIndex = cartItems.findIndex(
        (item) =>
          item.retailerProductId == cartItem.retailerProductId &&
          item.shippingType == cartItem.shippingType &&
          item.shippingTime == cartItem.shippingTime &&
          item.shippingDay == cartItem.shippingDay
      )
      if (cartItemIndex != -1) {
        cartItems.splice(cartItemIndex, 1)
      }
    }
    if (cartItems.length == 0) {
      clearCart()
    }
    setCartProducts([...cartItems])
    Cookies.set('cart', JSON.stringify(cartItems), { expires: 60 })
    return true
  }

  const changeShowPopUpDifferentRetailer = (value: boolean) => {
    setShowPopUpDifferentRetailer(value)
  }

  const calculateShippingPrices = async (
    newProductsAvailable: CartProductInfoProps[],
    clientFromIslands: string
  ) => {
    try {
      const response = await api.post<CalculateShippingPricesReturn>('/shipping/price', {
        cartProducts: newProductsAvailable,
        latitude: latitude ? latitude : 0,
        longitude: longitude ? longitude : 0,
        clientFromIslands,
      })
      if (response.status == 200) {
        setErrorCart(false)
      }
      return response.data
    } catch (error) {
      setErrorCart(true)
    }
  }
  const changeFromEmail = (value: boolean): void => {
    setFromEmail(value)
  }
  const changeCartId = (id: string): void => {
    setCartId(id)
  }
  const changeCartProducts = (products: MultiVendorCartItemProps[]): void => {
    setCartProducts(products)
  }
  const changeCartNote = (cartNote: string): void => {
    setCartNote(cartNote)
    Cookies.set('cart-note', cartNote)
  }

  const changePromoCode = (PromoCode: string): void => {
    setPromoCode(PromoCode)
    if (PromoCode === null) {
      Cookies.remove('promo-code')
    } else {
      Cookies.set('promo-code', promoCode)
    }
  }
  const changePromoCodeDiscount = (promoCode: number): void => {
    setPromoCodeDiscount(promoCode)
  }

  const changeIsPresent = (isPresent: boolean): void => {
    if (isPresent) {
      setIsPresent(true)
      Cookies.set('cart-present', 'true')
    } else {
      setIsPresent(false)
      Cookies.remove('cart-present')
    }
  }
  return (
    <MultiVendorCartContext.Provider
      value={{
        addItemToCart,
        cartId,
        productInfo,
        totalItems,
        cartNote,
        cartProducts,
        isLoading,
        isPresent,
        promoCode,
        isMiniCartVisible,
        removeItemFromCart,
        getCartProductsInformation,
        getCartCrossSells,
        updateItemQty,
        getSubTotalPrice,
        isAddingToCart,
        calculateShippingPrices,
        changeCartNote,
        changeIsPresent,
        changePromoCode,
        validateCart,
        removeItems,
        clearCart,
        promoCodeDiscount,
        changePromoCodeDiscount,
        cartCrossSells,
        errorShippingPrice,
        changeErrorShippingPrice,
        showPopUpDifferentRetailer,
        changeShowPopUpDifferentRetailer,
        changeCartId,
        changeCartProducts,
        changeFromEmail,
        fromEmail,
        changeMbwayPhone,
        mbwayPhone,
        errorCart,
      }}
    >
      {children}
    </MultiVendorCartContext.Provider>
  )
}

export const useMultiVendorCart = () => useContext(MultiVendorCartContext)
