import { Modal } from 'antd'

interface AutoDestroyModal {
  type: 'error' | 'success' | 'info' | 'warning' | 'confirm'
  title: string
  content?: string
  secondsToClose?: number
  afterClose?: () => void
}

const AutoDestroyModal = ({
  type,
  title,
  content,
  afterClose,
  secondsToClose = 3,
}: AutoDestroyModal) => {
  if (secondsToClose > 1) {
    const modal = Modal[type]({
      title,
      content,
      maskClosable: true,
      okButtonProps: { style: { display: 'none' } },
      afterClose,
    })
    setTimeout(() => {
      modal.destroy()
    }, secondsToClose * 1000)
  }

  return null
}

export { AutoDestroyModal }
